<template>
  <!--待认证列表-->
  <div style="padding: 20px;">
    <div v-if="showToexAmine == false">
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="77px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="企业名称:">
                    <el-input
                      clearable
                      v-model="where.company_name"
                      placeholder="请输入企业名称"/>
                  </el-form-item>
                </el-col>
              
                <el-col :lg="6" :md="12">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList">查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
            </div>
            <div>
             <!-- <el-button>导出数据</el-button> -->
             <el-button @click="ListSetup()">列表设置</el-button>
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >

              <!--自定义列显示 示例-->
              <template v-slot:status= "{ scope }">
                <div >
                  <img style="cursor: pointer" src="@/assets/images/home/yiqiyong.png" alt="" v-if="scope.row.status == 0" @click="Enable(scope.row)">
                  <img style="cursor: pointer" src="@/assets/images/home/weiqiyong.png" alt="" v-else @click="Disable(scope.row)">
                </div>
              </template>

              <!--自定义列显示 示例-->
              <template v-slot:approve_status= "{ scope }">
                <div v-if="scope.row.approve_status == 0">
                <span style="
                    width: 8px;
                    height: 8px;
                    background: #ff9e1c;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 10px;
                 "></span>
                  <span>待认证</span>
                </div>
                <div v-if="scope.row.approve_status == 1">
                  <span style="
                      width: 8px;
                      height: 8px;
                      background: #25eb61;
                      border-radius: 50%;
                      display: inline-block;
                      margin-right: 10px;
                   "></span>
                  <span>已认证</span>
                </div>
                <div v-if="scope.row.approve_status == -1">
                  <span style="
                      width: 8px;
                      height: 8px;
                      background: red;
                      border-radius: 50%;
                      display: inline-block;
                      margin-right: 10px;
                   "></span>
                  <span>未通过</span>
                </div>
              </template>


              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="warning" @click="Toexamine(scope.row)">认证审核</el-link>
              </template>
            </my-table>

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
          title="列表设置"
          :visible.sync="ListSetupShow"
          width="60%"
          center
          :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>

    </div>

    <!--服务商认证审核-->
    <ToExamine v-if="showToexAmine == true" :data="current" @showtoexamine="ShowToexAmine" @dome="getList"/>


  </div>
</template>

<script>

// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'

// 引入的接口
import {
  getfacilitator,
} from '@/api/yunli'

//引入服务商认证审核模块
import ToExamine from './toExamine/index.vue'
import Vue from "vue";

export default {
  // 组件生效
  components: {
    PackageSortElement,
    ToExamine
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "系统编号",
          // type: "index",
          // width: "70"
          prop: "id",
          isShow: true,
          fixed: 'left'
        },
        {
          label: "企业名称",
          prop: "company_name",
          isShow: true
        },
          {
          label: "企业简称",
          prop: "facilitator_abbreviation",
          isShow: true
        },
          {
          label: "老板账号",
          prop: "admin_account",
          isShow: true
        },
        {
          label: "创建时间",
          prop: "create_time",
          isShow: true
        },
        {
          label: "注册来源",
          prop: "genre",
          isShow: true,
          width: "100"
        },
        {
          label: "注册区域",
          prop: "registration_place_label",
          isShow: true
        },    
        {
          label: "备注",
          prop: "remark",
          isShow: true
        },
        {
          label: "认证状态",
          prop: "approve_status",
          slot: "approve_status",
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true,
          fixed: 'right'
        },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      //审核组件
      showToexAmine:false,
      current:null,

      //字典
      dict: {
        type:{}
      },

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '运力字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });
    console.log(this.dict)

    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
    // 获取列表
    getList(){
      getfacilitator(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },


    //点击认证审核
    Toexamine(row){
      this.current = row;
      //显示审核模块
      this.showToexAmine = true;
    },
    //接收审核子组件传递的值
    ShowToexAmine(value){
      this.showToexAmine = value;
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
